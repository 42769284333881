.container {
	@extend %position-relative;
	@extend %lost-clear;
	@extend %full-width;
	// max-width: $inner-wrap;
	padding: 44px;
	margin: 0 auto;

	@include media('below-desktop') {
		padding: 0 32px;
	}
	@include media('below-tablet') {
		padding: 0 24px;
	}
}

.wrapper-s,
%wrapper-s {
	@extend %position-relative;
	@include media('above-desktop') {
		padding: 0 124px;
	}
	@include media('below-desktop') {
		padding: 0 80px;
	}
	@include media('below-small') {
		padding: 0;
	}
}