.card-info {
  position: relative;

  &.-is-hidden {
    opacity: 0;
    pointer-events: none;
    height: 0;
    transition: all .5s;
  }
  &.-is-active {
    opacity: 1;
    pointer-events: visible;
    height: auto;
    transition: all .5s;
  }

  @include media('above-tablet') {
    &:hover {
      // & .card-info__img {
      //   background-image: url('../imgs/info-card-hover.jpg') !important;
      //   transition: background-image .4s;
      // }
      & .card-info__img {
        &.-hover {
          opacity: 1;
          transition: opacity .4s;
        }
      }
      & .card-info__subtitle {
        opacity: .8;
        transition: all .3s;
      }
      & .card-info__title {
        color: $c-font-hover;
        transition: all .3s;
      }
    }
  }
}

.card-info__top {
  margin-bottom: 18px;
}

.card-info__img {
  @extend %background-cover;
  height: 270px;
  min-height: 270px;
  width: 100%;
  min-width: 100%;

  transition: opacity .4s;

  &.-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .1s;
  }

	@include media('below-desktop') {
    min-height: 180px;
	}
	@include media('below-tablet') {
    min-height: 160px;
	}
}

.card-info__subtitle {
  font-family: $second-font-light;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 4px;

  transition: all .3s;
}

.card-info__title {
  font-size: 15px;
  letter-spacing: 0.7px;

  transition: all .3s;
}