.panel-filter {
  @extend %text--center;
  margin-bottom: 70px;
  width: 100%;

	@include media('below-medium') {
    margin-bottom: 48px;
	}
}

.panel-filter__wrapper {
  &.-rwd-show {
    display: none;
  }
  @include media('below-tablet') {
    &.-rwd-show {
      display: block;
    }
    &.-rwd-hide {
      display: none;
    }
  }
}

.panel-filter__btn {
  @extend %last-no-margin;
  font-family: $second-font;
  font-size: 14px;
  letter-spacing: 1.5px;
  margin-right: 72px;

  transition: all .3s;

  @include media('below-small') {
    margin-right: 40px;
  }

  &.-is-active {
    color: $c-font-hover;
  }
	&:hover {
		color: $c-font-hover;
		transition: all .3s;
	}
}

.panel-filter__select {
  @extend %flex-center-all;
  font-family: $second-font;
  font-size: 12px;
  letter-spacing: 1.3px;

  & .panel-filter__select-main {
    padding: 0 16px 0 2px;
    letter-spacing: 1.2px;
  }

  & .panel-filter__select-icon {
    display: inline-block;
    font-size: 12px;
    transform: translateY(-1px) rotate(90deg);
  }
}