/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

*,*::before,*::after { box-sizing: border-box; }

a:focus {
	outline: none;
}

/*form reset*/
input,
label,
select,
button,
textarea {
	margin: 0;
	border: 0;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
	background: none;
	line-height: 1;
	text-align: left;
	font-size: inherit;
	font-family: inherit;
	color: inherit;
	box-sizing: border-box;
}

button,
textarea,
input,
select {
	outline: 0;
}

select {
	appearance: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
	cursor: pointer;
}

a {
	text-decoration: none;
}
