.home__hero-wrapper {
  padding-top: 8px;
  margin-bottom: 80px;

	@include media('below-desktop') {
    margin-bottom: 40px;
	}
	@include media('below-tablet') {
    margin-bottom: 32px;
	}
}

.home__heading {
  @extend %text--center;
  font-family: $second-font;
  font-size: 34px;
  letter-spacing: 1.6px;
  line-height: 50px;

	@include media('below-small') {
    font-size: 17px;
    letter-spacing: 0.6px;
    line-height: 24px;
	}
	@include media('below-mobile') {
    font-size: 16px;
	}
}