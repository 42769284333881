.service {
  color: $c-main-light;

	@include media('below-small') {
    margin-top: -16px;
    padding-top: 0;
	}
}

.service-header {
  padding-top: 120px;
  margin-bottom: 80px;
  text-align: center;

  @include media('below-small') {
    padding-top: 96px;
    margin-bottom: 72px;
  }
  @include media('below-tablet') {
    padding-top: 56px;
    margin-bottom: 56px;
  }

  .service-header__title {
    font-size: 30px;
    letter-spacing: 3.6px;
    margin-bottom: 22px;

    @include media('below-tablet') {
      font-size: 22px;
      letter-spacing: 2.6px;
      line-height: 30px;

      .-m-break {
        display: block;
      }
    }
  }
  .service-header__subtitle {
    font-size: 14px;
    letter-spacing: 1.6px;

    @include media('below-tablet') {
      font-size: 12px;
      letter-spacing: 1.5px;
      line-height: 20px;

      .-m-break {
        display: block;
      }
    }
  }
}

.service-grids {
  margin-bottom: 96px;

  @include media('below-tablet') {
    margin-bottom: 64px;
  }

  & .service-grids__container {
    @extend %flex-between;
    flex-wrap: wrap;
    margin-bottom: 48px;

    &.-second {
      justify-content: initial;

      & .service-grids__wrapper:nth-child(2) {
        margin-left: auto;
      }
      & .service-grids__wrapper:nth-child(3) {
        margin-right: 5%;
      }

      @include media('below-tablet') {
        & .service-grids__wrapper {
          &.-single {
            margin-right: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    &.-three {
      .card-icon {
        min-width: 26%;
        max-width: 26%;
      }
    }

    & .service-grids__wrapper {
      @extend %flex;
      @extend %last-no-margin-btm;
      flex-direction: column;
      margin-bottom: 48px;

      &.-two {
        width: 45%;

        & .card-icon {
          max-width: 43%;
          min-width: 43%;
        }
      }
      &.-three {
        width: 100%;
      }
      &.-single {
        width: 20%;
        flex-direction: initial;
        justify-content: space-between;

        & .card-icon {
          max-width: 100%;
          min-width: 100%;
        }
      }

      & .service-grids__cards {
        @extend %flex-between;
        width: 100%;
        min-height: 280px;
        margin-bottom: 48px;
      }
    }

    & .service-grids__single-card {
      width: 100%;
    }

    @include media('below-tablet') {
      flex-direction: column;

      &.-two, &.-three, &.-four {
        .card-icon {
          max-width: 100%;
          margin-bottom: 56px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .service-grids__wrapper {
        margin-bottom: 56px;
        &.-two,
        &.-single {
          width: 100%;

          & .card-icon {
            max-width: 100%;
            margin-bottom: 64px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &.-singles {
          flex-direction: column;
        }
        .service-grids__cards {
          flex-direction: column;
          min-height: 0;
        }
        .service-grids__single-card {
          width: 100%;
          margin-bottom: 56px;
        }
        .card-icon {
          max-width: 100%;
          margin-bottom: 64px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .service-grids__wrapper {
    width: 100%;
  }
}

.service-grids__dividers {
  @extend %flex-between;

  .service-grids__divider {
    min-width: 47%;
  }
}

.service-grids__divider {
  @extend %text--center;
  @extend %position-relative;

  &:after {
    @extend %position-absolute;
    bottom: calc(50% - 1px);
    right: 0;
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed $c-main-light;
  }

  .service-grids__divider-text {
    @extend %position-relative;
    @extend %inlineblock;
    z-index: 2;
    background-color: $c-bg-dark;
    padding: 8px 32px;
    border: 1px solid $c-main-light;

    &:before,
    &:after {
      @extend %position-absolute;
      bottom: 0;
      content: '';
      width: 100%;
      height: 100%;
      box-sizing: content-box;
    }
    &:before {
      right: 1px;
      border-left: 12px solid $c-bg-dark;
    }
    &:after {
      left: 1px;
      border-right: 12px solid $c-bg-dark;
    }
  }
}

.service-parallax {
  @extend %background-cover;
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 520px;

  @include media('below-tablet') {
    min-height: 220px;
    background-attachment: scroll;
  }
}

.service-contact {
  @extend %text--center;
  padding: 124px 0 72px 0;

  .service-contact__title {
    font-family: $second-font-light;
    font-size: 30px;
    letter-spacing: 3px;
    color: $c-main-dark;
  }

  .transit-icon {
    @extend %position-relative;
  }

  @include media('below-tablet') {
    padding: 64px 0 48px 0;

    .service-contact__title {
      font-size: 20px;
      letter-spacing: 2px;
    }
  }
}

.service-contact__mails {
  @extend %position-relative;
  margin-bottom: 24px;
  width: 100%;
  height: 90px;

  @include media('below-tablet') {
    margin-bottom: 0;
  }
}

.service-contact__icon {
  @extend %position-absolute;
  left: calc(50% - 46px);
  top: 0;
  opacity: 0;
  pointer-events: none;
}