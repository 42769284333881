@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.p-transition {
  /** Basic styles for an animated element */
  .p-transition__element {
    animation-duration: 0.4s;
    transition-timing-function: ease-in;
    animation-fill-mode: both;
  }

  /** An element that fades in */
  .p-transition__element-fadeout {
    animation-name: fadeOut;
  }

  /** An element that fades in and slides up */
  .p-transition__element-fadeinup {
    animation-name: fadeInUp;
  }

  &.-is-existing .p-transition__element {
    animation-direction: alternate-reverse;
  }
}