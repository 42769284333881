.btn-basic {
  @extend %text--center;
  font-size: 20px;
  letter-spacing: 2.86px;
  padding: 16px 112px;
  border: 1px solid $c-main-dark;
  transition: .3s;

  &:hover {
    color: $c-main-light;
    background-color: $c-main-dark;
    transition: .3s;
  }
}