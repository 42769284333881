/* ------------------------------------
 *  Width and Height
 * ------------------------------------ */

/*wrap*/
$inner-wrap: 1350px;
$gutter: 16px;

/*nav*/
$header: 140px;
$header-m: 80px;

/*z-index*/
$z-menu: 60;
$z-header: 80;
$z-modal: 200;


/* ------------------------------------
 *  Color
 * ------------------------------------ */

$dark-0: #313131;
$gray-0: #E8E8E8;
$gray-1: #9B9B9B;
$gray-3: #DADADA;
$gray-4: #5A5A5A;

$c-error: #FF2929;
$c-main-dark: black;
$c-main-light: white;
$c-font-gray: $gray-1;
$c-font-hover: $gray-4;
$c-font-light: $gray-3;
$c-bg-dark: $dark-0;
$c-bg-gray: $gray-0;

$default-color: $c-main-dark;


/* ------------------------------------
 *  Font
 * ------------------------------------ */

$font-zh: "PingFangTC-Regular", "微軟正黑體", Helvetica, Arial, sans-serif;
$font-zh-light: "PingFangTC-Light", "微軟正黑體", Helvetica, Arial, sans-serif;
$font-en: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
$font-en-light: "FrutigerLTPro-Light", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;

$base-font: $font-zh;
$base-font-light: $font-zh-light;
$second-font: $font-en;
$second-font-light: $font-en-light;
