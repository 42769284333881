.projects {
  padding-top: 64px;

  @include media('below-small') {
    padding-top: 40px;
  }
  @include media('below-tablet') {
    padding-top: 32px;
  }
}

.projects__heading {
  @extend %text--center;
  font-family: $second-font;
  font-size: 34px;
  letter-spacing: 1.6px;
  line-height: 50px;
}

.projects__cards {
  @extend %flex-wrap;
  width: 100%;
  margin-bottom: 24px;
  overflow: -webkit-paged-x;

  @include media('below-small') {
    justify-content: space-between;
  }

	@include media('below-tablet') {
    flex-wrap: nowrap;
    flex-direction: column;
	}

  & .card-info {
    @extend %last-no-margin-btm;
    width: calc( ( 100% - #{$gutter} * 2 ) / 3.001 );
    margin-bottom: 40px;
    margin-right: $gutter;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @include media('below-small') {
      width: calc(50% - #{$gutter});

      &:nth-child(3n) {
        margin-right: $gutter;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @include media('below-tablet') {
      width: 100%;
      margin-bottom: 32px;
    }

    &.-is-hidden {
      margin-bottom: 0;
    }
  }
}

.projects__show-btn {
  display: none;
	@include media('below-tablet') {
    display: flex;
    margin-bottom: 36px;
	}
}

