header {
  position: fixed;
  width: 100vw;
  background-color: $c-main-light;
  z-index: $z-header;

  &.-bg-dark {
    .header-links__item {
      color: $c-main-light;
      &:hover {
        color: $c-font-light;
      }
      &.-is-active {
        color: $c-font-light;
      }
    }
    .header-logo {
      color: $c-main-light;
    }
    .header-ham {
      &:before,
      &:after {
        background-color: $c-font-light;
      }
    }
    .header-ham__close {
      background-color: $c-main-light;
    }
  }
}

.header-wrapper {
  @extend %flex-center-horizontal;
  min-height: $header;

	@include media('below-small') {
    min-height: $header-m;

    & .flex-between {
      align-items: center;
    }
  }
}

.header-logo {
  font-size: 66px;

	@include media('below-small') {
		font-size: 48px;
	}
	@include media('below-mobile') {
		font-size: 40px;
	}
}

.header-links {
  font-family: $second-font;

  & .header-links__item {
    font-size: 32px;
    letter-spacing: 1.3px;
    margin-right: 90px;

    @include media('below-desktop') {
      margin-right: 56px;
    }

    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $c-font-hover;
    }
    &.-is-active {
      color: $c-font-hover;
    }
  }

	@include media('below-small') {
		display: none;
	}
}

.header-ham {
  height: 16px;

  display: none;
	@include media('below-small') {
		display: block;
  }
	@include media('below-mobile') {
		// font-size: 32px;
  }

  position: relative;
  width: 38px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: black;
    transition: transform .3s;
  }

  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }

  &.-is-active {
    &:before {
      transform: translateY(6px);
      transition: transform .3s;
    }
    &:after {
      transform:  translateY(-6px);
      transition: transform .3s;
    }
  }
}

.header-ham__close {
  width: 40px;
  height: 2px;
  background-color: $c-main-dark;
  display: none;

	@include media('below-mobile') {
		width: 32px;
  }

  &.-is-active {
    display: block;
  }
}