.menu {
  position: absolute;
  right: 0;
  overflow-x: hidden;
  z-index: $z-menu;
  width: 100vw;
  height: 100vh;
  background-color: $c-main-light;
  transform: translateX(100vw);
  transition: .3s ease-out;

  &.-is-active {
    transform: translateX(0);
    transition: .3s ease-out;
  }
  &.-bg-dark {
    background-color: $c-bg-dark;
  }
  &.-bg-gray {
    background-color: $c-bg-gray;
  }

  display: none;
	@include media('below-small') {
		display: block;
	}
}

.menu__container {
  @extend %flex-between;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  padding: $header-m 0 24px 0;
}

.menu__links {
  padding: 64px 48px 0 48px;

	@include media('above-tablet') {
    padding-top: 104px;
		text-align: center;
	}
}

.menu__links-item {
  @extend %last-no-margin-btm;
  display: block;
  font-family: $second-font;
  font-size: 28px;
  letter-spacing: 1px;
  margin-bottom: 48px;

  .-bg-dark &,
  .-bg-gray & {
    color: $c-main-light;
  }

	@include media('above-tablet') {
		font-size: 40px;
	}
}

.menu__footer {
  @extend %text--center;
}

.menu__footer-item {
  font-family: $second-font;
  font-size: 13px;
  letter-spacing: 1px;

  .-bg-dark &,
  .-bg-gray & {
    color: $c-main-light;
  }

	@include media('above-tablet') {
		font-size: 20px;
	}
}