.about-hero-wrapper {
  margin-bottom: 100px;

	@include media('below-desktop') {
    margin-bottom: 56px;
	}
	@include media('below-tablet') {
    margin-bottom: 48px;
	}
}

.about-section {
  margin-bottom: 112px;

  @include media('below-tablet') {
    margin-bottom: 64px;
  }

  .about-section__indent {
    margin-left: 20%;

    @include media('below-desktop') {
      margin-left: 10%;
    }
    @include media('below-tablet') {
      margin-left: 0;
    }
  }
  .about-section__title {
    font-family: $second-font;
    font-size: 40px;
    letter-spacing: 1.2px;
    line-height: 50px;
    margin-bottom: 96px;
    width: 65%;

    @include media('below-desktop') {
      width: 80%;
      margin-bottom: 64px;
    }
    @include media('below-tablet') {
      width: 100%;
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 30px;
      margin-bottom: 40px;
    }
  }
  .about-section__paragraph {
    @extend %last-no-margin-btm;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.6px;
    line-height: 35px;
    margin-bottom: 32px;

    @include media('below-tablet') {
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }
}