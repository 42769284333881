@charset "UTF-8";
/* inject:scss */
/*Clearfix*/
.clear:before, .clear:after {
  content: " ";
  display: table; }

.clear:after {
  clear: both; }

.column, .container {
  lost-utility: clearfix; }

/*Background Size Cover*/
.background-cover,
.card-info__img,
.hero-main,
.service-parallax {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

/*Position*/
.position-relative,
.container,
.wrapper-s,
.form-basic__bar,
.footer-links__item,
.contact-modal,
.service-grids__divider,
.service-grids__divider .service-grids__divider-text,
.service-contact .transit-icon,
.service-contact__mails {
  position: relative; }

.position-absolute,
.form-basic__bar .icon,
.contact-modal .contact-modal__close,
.service-grids__divider:after,
.service-grids__divider .service-grids__divider-text:before,
.service-grids__divider .service-grids__divider-text:after,
.service-contact__icon {
  position: absolute; }

.position-fixed {
  position: fixed; }

/*Display*/
.block,
.align--center,
.btn-icon-text__text,
.contact-info .contact__info-text,
.project-content__infos .project-content__info .project-content__info-title {
  display: block; }

.inlineblock,
.service-grids__divider .service-grids__divider-text {
  display: inline-block; }

.align--center {
  margin-left: auto;
  margin-right: auto; }

/*Full-width elements*/
.table, .container, body, html,
.full-width {
  width: 100%; }

/*Full-height elements*/
.table,
.full-height {
  height: 100%; }


.full-view-height {
  height: 100vh; }

/*Text alignment*/

.text--left {
  text-align: left; }


.text--right {
  text-align: right; }

.panel-filter, .btn-basic, .menu__footer, .contact-modal, .home__heading, .projects__heading, .service-grids__divider, .service-contact,
.text--center {
  text-align: center; }


.text--justify {
  text-align: justify; }


.inline--top {
  display: inline-block;
  vertical-align: top; }


.inline--middle {
  display: inline-block;
  vertical-align: middle; }


.inline--bottom {
  display: inline-block;
  vertical-align: bottom; }

/*Text style*/

.text--b {
  font-weight: 700; }


.text--green {
  color: #008787; }

/*Align center by table*/
.table {
  display: table; }

.table-cell {
  display: table-cell;
  vertical-align: middle; }

/*Flex*/
.gallery__wrapper, .subway-bars__bar, .service-grids .service-grids__container .service-grids__wrapper,
.flex {
  display: flex; }

.bookshelf__container, .panel-filter__select, .btn-icon-text,
.flex-center-all {
  display: flex;
  align-items: center;
  justify-content: center; }

.modal, .header-wrapper,
.flex-center-horizontal {
  display: flex;
  align-items: center; }

.bookshelf, .form-basic__bar-title, .gallery__container, .menu__container, .project-content__btm, .project-content__infos, .service-grids .service-grids__container, .service-grids .service-grids__container .service-grids__wrapper .service-grids__cards, .service-grids__dividers,
.flex-between {
  display: flex;
  justify-content: space-between; }


.flex-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.projects__cards,
.flex-wrap {
  display: flex;
  flex-wrap: wrap; }

.hide {
  display: none !important; }

.form-basic__bar:last-child, .gallery__img.-s:last-child, .gallery__wrapper.-s:last-child, .gallery__wrapper.-m:last-child, .panel-filter__btn:last-child, .footer-links__item:last-child {
  margin-right: 0; }

.menu__links-item:last-child, .about-section .about-section__paragraph:last-child, .contact-info .contact__info-wrapper:last-child, .project-content__infos .project-content__info .project-content__info-list-item:last-child, .projects__cards .card-info:last-child, .service-grids .service-grids__container .service-grids__wrapper:last-child {
  margin-bottom: 0; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 10%, 0); }
  100% {
    opacity: 1;
    transform: none; } }

.p-transition {
  /** Basic styles for an animated element */
  /** An element that fades in */
  /** An element that fades in and slides up */ }
  .p-transition .p-transition__element {
    animation-duration: 0.4s;
    transition-timing-function: ease-in;
    animation-fill-mode: both; }
  .p-transition .p-transition__element-fadeout {
    animation-name: fadeOut; }
  .p-transition .p-transition__element-fadeinup {
    animation-name: fadeInUp; }
  .p-transition.-is-existing .p-transition__element {
    animation-direction: alternate-reverse; }

/* ------------------------------------
 *  Width and Height
 * ------------------------------------ */
/*wrap*/
/*nav*/
/*z-index*/
/* ------------------------------------
 *  Color
 * ------------------------------------ */
/* ------------------------------------
 *  Font
 * ------------------------------------ */
/* endinject */
/* inject:scss */
.column.column--margin-btm {
  margin-bottom: 16px; }

.column__item {
  margin-top: 24px; }
  .column__item.no-margin {
    margin-top: 0; }
  .column--no-gap .column__item {
    margin-top: 0; }

.column--6 .column__item {
  lost-column: 1/6; }
  .column--6 .column__item:nth-child(-n+6) {
    margin-top: 0; }

.column--6.column--wide .column__item {
  lost-column: 1/6 6 48px; }
  .column--6.column--wide .column__item:nth-child(-n+6) {
    margin-top: 0; }

.column--6.column--no-gap .column__item {
  lost-column: 1/6 6 0; }

@media (min-width: 768px) {
  .column--5 .column__item {
    lost-column: 1/5; }
    .column--5 .column__item:nth-child(-n+5) {
      margin-top: 0; } }

@media (max-width: 767px) {
  .column--5 .column__item {
    lost-column: 1/2; } }

@media (min-width: 768px) {
  .column--5.column--wide .column__item {
    lost-column: 1/5 5 48px; }
    .column--5.column--wide .column__item:nth-child(-n+5) {
      margin-top: 0; } }

@media (max-width: 767px) {
  .column--5.column--wide .column__item {
    lost-column: 1/2 2 48px; } }

@media (min-width: 768px) {
  .column--5.column--no-gap .column__item {
    lost-column: 1/5 5 0; }
    .column--5.column--no-gap .column__item:nth-child(-n+5) {
      margin-top: 0; } }

@media (max-width: 767px) {
  .column--5.column--no-gap .column__item {
    lost-column: 1/2 2 0; } }

@media (min-width: 851px) {
  .column--4 .column__item {
    lost-column: 1/4; }
    .column--4 .column__item:nth-child(-n+4) {
      margin-top: 0; } }

@media (max-width: 850px) {
  .column--4 .column__item {
    lost-column: 1/2; } }

@media (max-width: 767px) {
  .column--4 .column__item {
    lost-column: 1/1; } }

.column--4 .column__item--3 {
  lost-column: 3/4; }

@media (min-width: 1025px) {
  .column--4.column--wide .column__item {
    lost-column: 1/4 4 48px; }
    .column--4.column--wide .column__item:nth-child(-n+4) {
      margin-top: 0; } }

@media (max-width: 1024px) {
  .column--4.column--wide .column__item {
    lost-column: 1/2 2 48px; } }

@media (max-width: 550px) {
  .column--4.column--wide .column__item {
    lost-column: 1/1; } }

.column--4.column--wide .column__item--3 {
  lost-column: 3/4 4 48px; }

@media (min-width: 1025px) {
  .column--4.column--no-gap .column__item {
    lost-column: 1/4 4 0; }
    .column--4.column--no-gap .column__item:nth-child(-n+4) {
      margin-top: 0; } }

@media (max-width: 1024px) {
  .column--4.column--no-gap .column__item {
    lost-column: 1/2 2 0; } }

@media (max-width: 550px) {
  .column--4.column--no-gap .column__item {
    lost-column: 1/1 1 0; } }

@media (min-width: 768px) {
  .column--3 .column__item {
    lost-column: 1/3; }
    .column--3 .column__item:nth-child(-n+3) {
      margin-top: 0; } }

@media (max-width: 767px) {
  .column--3 .column__item {
    lost-column: 1/1; } }

.column--3 .column__item--2 {
  lost-column: 2/3; }
  @media (max-width: 767px) {
    .column--3 .column__item--2 {
      lost-column: 1/1; }
      .column--3 .column__item--2:first-child {
        margin-top: 0; } }

@media (min-width: 851px) {
  .column--3.column--wide .column__item {
    lost-column: 1/3 3 48px; }
    .column--3.column--wide .column__item:nth-child(-n+3) {
      margin-top: 0; } }

@media (max-width: 850px) {
  .column--3.column--wide .column__item {
    lost-column: 1/3 3 24px; } }

@media (max-width: 767px) {
  .column--3.column--wide .column__item {
    lost-column: 1/1;
    margin-top: 24px; } }

@media (min-width: 768px) {
  .column--3.column--no-gap .column__item {
    lost-column: 1/3 3 0; }
    .column--3.column--no-gap .column__item:nth-child(-n+3) {
      margin-top: 0; } }

@media (min-width: 768px) {
  .column--2 .column__item {
    lost-column: 1/2; }
    .column--2 .column__item:nth-child(-n+2) {
      margin-top: 0; } }

@media (max-width: 767px) {
  .column--2 .column__item {
    lost-column: 1/1; }
    .column--2 .column__item:first-child {
      margin-top: 0; } }

@media (min-width: 768px) {
  .column--2.column--wide .column__item {
    lost-column: 1/2 2 48px; }
    .column--2.column--wide .column__item:nth-child(-n+2) {
      margin-top: 0; } }

@media (min-width: 768px) {
  .column--2.column--no-gap .column__item {
    lost-column: 1/2 2 0; }
    .column--2.column--no-gap .column__item:nth-child(-n+2) {
      margin-top: 0; } }

.column--8-4 .column__item:nth-child(1) {
  lost-column: 2/3; }

.column--8-4 .column__item:nth-child(2) {
  lost-column: 1/3; }

@media (min-width: 768px) {
  .column--8-4 .column__item:nth-child(-n+2) {
    margin-top: 0; } }

@media (max-width: 767px) {
  .column--8-4 .column__item:first-child {
    margin-top: 0; }
  .column--8-4 .column__item:nth-child(1), .column--8-4 .column__item:nth-child(2) {
    lost-column: 1/1; } }

.column--8-4.column--reverse .column__item:nth-child(1) {
  lost-column: 1/3; }

.column--8-4.column--reverse .column__item:nth-child(2) {
  lost-column: 2/3; }

.column--8-4.column--reverse .column__item.column__item--offset {
  lost-column: 7/12;
  lost-offset: 1/12; }

.column--9-3 > .column__item:first-child {
  lost-column: 3/4; }

.column--9-3 > .column__item:last-child {
  lost-column: 1/4; }

@media (min-width: 768px) {
  .column--9-3 > .column__item:nth-child(-n+2) {
    margin-top: 0; } }

@media (max-width: 767px) {
  .column--9-3 > .column__item {
    lost-column: 1/1; } }

.column--9-3.column--reverse .column__item:first-child {
  lost-column: 1/4; }

.column--9-3.column--reverse .column__item:last-child {
  lost-column: 5/4; }

.column--5-7 .column__item:first-child {
  lost-column: 5/12; }
  @media (min-width: 851px) {
    .column--5-7 .column__item:first-child {
      lost-column: 5/12; } }
  @media (max-width: 850px) {
    .column--5-7 .column__item:first-child {
      lost-column: 6/13; } }
  @media (max-width: 767px) {
    .column--5-7 .column__item:first-child {
      lost-column: 1/1; } }

@media (min-width: 851px) {
  .column--5-7 .column__item:last-child {
    lost-column: 7/12; } }

@media (max-width: 850px) {
  .column--5-7 .column__item:last-child {
    lost-column: 7/13; } }

@media (max-width: 767px) {
  .column--5-7 .column__item:last-child {
    lost-column: 1/1; } }

@media (min-width: 768px) {
  .column--5-7 .column__item:nth-child(-n+2) {
    margin-top: 0; } }

@media (min-width: 851px) {
  .column--5-7.column--reverse .column__item:first-child {
    lost-column: 7/12; } }

@media (max-width: 850px) {
  .column--5-7.column--reverse .column__item:first-child {
    lost-column: 7/13; } }

@media (max-width: 767px) {
  .column--5-7.column--reverse .column__item:first-child {
    lost-column: 1/1; } }

@media (min-width: 851px) {
  .column--5-7.column--reverse .column__item:last-child {
    lost-column: 5/12; } }

@media (max-width: 850px) {
  .column--5-7.column--reverse .column__item:last-child {
    lost-column: 6/13; } }

@media (max-width: 767px) {
  .column--5-7.column--reverse .column__item:last-child {
    lost-column: 1/1; } }

.container {
  padding: 44px;
  margin: 0 auto; }
  @media (max-width: 1024px) {
    .container {
      padding: 0 32px; } }
  @media (max-width: 767px) {
    .container {
      padding: 0 24px; } }

@media (min-width: 1025px) {
  .wrapper-s {
    padding: 0 124px; } }

@media (max-width: 1024px) {
  .wrapper-s {
    padding: 0 80px; } }

@media (max-width: 850px) {
  .wrapper-s {
    padding: 0; } }

@font-face {
  font-family: "icon";
  src: url("fonts/icon.eot");
  src: url("fonts/icon.eot?#iefix") format("eot"), url("fonts/icon.ttf") format("truetype"), url("fonts/icon.woff") format("woff"), url("fonts/icon.svg#icon") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon-base,
[class^="icon-"], [class*=" icon-"] {
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow:before {
  content: "\EA01"; }

.icon-chevron:before {
  content: "\EA02"; }

.icon-cross:before {
  content: "\EA03"; }

.icon-ham:before {
  content: "\EA04"; }

.icon-logo-main:before {
  content: "\EA05"; }

.icon-open-link:before {
  content: "\EA06"; }

/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *::before, *::after {
  box-sizing: border-box; }

a:focus {
  outline: none; }

/*form reset*/
input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  text-align: left;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  box-sizing: border-box; }

button,
textarea,
input,
select {
  outline: 0; }

select {
  appearance: none; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset; }

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  cursor: pointer; }

a {
  text-decoration: none; }

@lost gutter 24px;
body, html {
  position: relative;
  font-family: "PingFangTC-Regular", "微軟正黑體", Helvetica, Arial, sans-serif;
  color: black;
  font-size: 16px;
  background-color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.8);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }
  body.-is-fixed, html.-is-fixed {
    position: fixed;
    overflow-y: hidden; }

a {
  color: black;
  transition: all .3s; }
  a:hover {
    transition: all .3s; }

.body-container {
  padding-top: 140px; }
  @media (max-width: 850px) {
    .body-container {
      padding-top: calc(80px + 16px); } }

.-bg-dark {
  background-color: #313131; }

.-bg-gray {
  background-color: #E8E8E8; }

/* endinject */
/* inject:scss */
.bookshelf {
  flex-wrap: wrap; }

.bookshelf__container {
  height: 140px;
  min-width: 20%;
  margin-bottom: 64px; }
  @media (max-width: 767px) {
    .bookshelf__container {
      height: auto;
      max-width: 40%;
      margin-bottom: 40px; }
      .bookshelf__container:nth-last-child(-n+2) {
        margin-bottom: 0; }
      .bookshelf__container img {
        max-width: 100%; } }

.form-basic__wrapper {
  margin-bottom: 40px; }

.form-basic__bar {
  margin-bottom: 26px; }
  @media (max-width: 767px) {
    .form-basic__bar {
      margin-bottom: 24px; } }
  .form-basic__bar .icon {
    bottom: 17px;
    right: 16px; }
    .form-basic__bar .icon.icon-chevron {
      transform: rotate(90deg); }
  .form-basic__bar.-is-error .form-basic__bar-title {
    color: #FF2929; }

.form-basic__bar-title {
  margin-bottom: 16px; }
  @media (max-width: 767px) {
    .form-basic__bar-title {
      margin-bottom: 8px; } }
  .form-basic__bar-title .form-basic__bar-label {
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 30px;
    margin-left: 2px; }
    @media (max-width: 767px) {
      .form-basic__bar-title .form-basic__bar-label {
        font-size: 16px;
        letter-spacing: 1.6px; } }
  .form-basic__bar-title .form-basic__bar-msg {
    font-size: 13px;
    letter-spacing: 1.3px;
    line-height: 30px; }
    @media (max-width: 767px) {
      .form-basic__bar-title .form-basic__bar-msg {
        font-size: 12px; } }

.form-basic__bar-input,
.form-basic__bar-select {
  font-family: "PingFangTC-Light", "微軟正黑體", Helvetica, Arial, sans-serif;
  font-size: 20px;
  letter-spacing: 2px;
  width: 100%;
  padding: 14px;
  background-color: white; }
  .form-basic__bar-input::placeholder,
  .form-basic__bar-select::placeholder {
    color: #9B9B9B; }
  @media (max-width: 767px) {
    .form-basic__bar-input,
    .form-basic__bar-select {
      font-size: 16px;
      letter-spacing: 1.6px; } }

.form-basic__bar-select {
  border-radius: 0;
  border: 1px solid black;
  color: #9B9B9B; }

@media (max-width: 767px) {
  .gallery__img {
    margin-bottom: 24px; }
    .gallery__img:last-child {
      margin-bottom: 0; } }

.gallery__container {
  margin-bottom: 48px; }
  @media (max-width: 767px) {
    .gallery__container {
      flex-direction: column;
      margin-bottom: 24px; } }

.gallery__wrapper {
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%; }
  @media (max-width: 767px) {
    .gallery__wrapper {
      flex-direction: column;
      margin-bottom: 24px; }
      .gallery__wrapper:last-child {
        margin-bottom: 0; } }
  .gallery__wrapper.-s {
    max-width: calc(100% / 3 - 3%);
    margin-right: 3%; }
    @media (max-width: 767px) {
      .gallery__wrapper.-s {
        max-width: 100%;
        margin-right: 0; } }
  .gallery__wrapper.-m {
    margin-right: 3%; }
    @media (max-width: 767px) {
      .gallery__wrapper.-m {
        margin-right: 0; } }
  .gallery__wrapper .gallery__img {
    max-width: 100%;
    min-width: 100%; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background-color: black;
  padding: 48px 0;
  transition: .3s;
  pointer-events: none;
  opacity: 0; }
  .modal.-is-active {
    transition: .3s;
    pointer-events: visible;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8); }

.panel-filter {
  margin-bottom: 70px;
  width: 100%; }
  @media (max-width: 1280px) {
    .panel-filter {
      margin-bottom: 48px; } }

.panel-filter__wrapper.-rwd-show {
  display: none; }

@media (max-width: 767px) {
  .panel-filter__wrapper.-rwd-show {
    display: block; }
  .panel-filter__wrapper.-rwd-hide {
    display: none; } }

.panel-filter__btn {
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
  margin-right: 72px;
  transition: all .3s; }
  @media (max-width: 850px) {
    .panel-filter__btn {
      margin-right: 40px; } }
  .panel-filter__btn.-is-active {
    color: #5A5A5A; }
  .panel-filter__btn:hover {
    color: #5A5A5A;
    transition: all .3s; }

.panel-filter__select {
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 12px;
  letter-spacing: 1.3px; }
  .panel-filter__select .panel-filter__select-main {
    padding: 0 16px 0 2px;
    letter-spacing: 1.2px; }
  .panel-filter__select .panel-filter__select-icon {
    display: inline-block;
    font-size: 12px;
    transform: translateY(-1px) rotate(90deg); }

.subway-bars__bar {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid black; }
  @media (max-width: 767px) {
    .subway-bars__bar {
      justify-content: space-between; } }

.subway-bars__bar-left {
  width: 20%;
  padding-left: 4px; }
  @media (max-width: 767px) {
    .subway-bars__bar-left {
      width: 25%; } }

.subway-bars__bar-right {
  width: 80%; }
  @media (max-width: 767px) {
    .subway-bars__bar-right {
      width: 75%; } }

.subway-bars__bar-note,
.subway-bars__bar-title {
  letter-spacing: 1.5px; }
  @media (max-width: 767px) {
    .subway-bars__bar-note,
    .subway-bars__bar-title {
      font-size: 12px;
      letter-spacing: 0.8px;
      line-height: 20px;
      word-wrap: break-word; } }

@keyframes transitionIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes transitionOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.transit-icon .transit-icon__target {
  opacity: 0; }
  .transit-icon .transit-icon__target:first-child {
    opacity: 1; }

.transit-icon.-three:hover .transit-icon__target {
  animation-fill-mode: forwards;
  animation-duration: 0.8s; }
  .transit-icon.-three:hover .transit-icon__target:first-child {
    animation-name: transitionOut; }
  .transit-icon.-three:hover .transit-icon__target:nth-child(2) {
    animation-name: transitionIn;
    animation-delay: 0.2s; }
  .transit-icon.-three:hover .transit-icon__target:last-child {
    animation-name: transitionIn;
    animation-delay: 0.4s; }

.btn-basic {
  font-size: 20px;
  letter-spacing: 2.86px;
  padding: 16px 112px;
  border: 1px solid black;
  transition: .3s; }
  .btn-basic:hover {
    color: white;
    background-color: black;
    transition: .3s; }

.btn-icon-text {
  transition: all .5s; }
  .btn-icon-text.-is-hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all .5s; }
  .btn-icon-text .icon-chevron {
    transform: rotate(90deg); }
  .btn-icon-text .icon-arrow.-right {
    transform: rotate(180deg); }
  .btn-icon-text .icon {
    font-size: 22px; }
    @media (max-width: 767px) {
      .btn-icon-text .icon {
        font-size: 12px;
        margin-top: -2px; } }
  .btn-icon-text.-icon-left .icon {
    margin-right: 18px; }
  .btn-icon-text.-icon-right .icon {
    margin-left: 18px; }

.btn-icon-text__text {
  font-family: "FrutigerLTPro-Light", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 30px;
  letter-spacing: 5.08px;
  margin-right: 10px; }
  @media (max-width: 767px) {
    .btn-icon-text__text {
      font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
      font-size: 12px;
      letter-spacing: 1.3px; } }
  .-s .btn-icon-text__text {
    font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    margin-top: 1px; }
  .-icon-left .btn-icon-text__text,
  .-icon-right .btn-icon-text__text {
    margin-right: 0; }

.card-icon {
  text-align: center; }

.card-icon__img {
  margin-bottom: 16px; }
  @media (max-width: 767px) {
    .card-icon__img {
      margin-bottom: 8px; } }

.card-icon__note {
  display: block;
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 14px;
  letter-spacing: 0.6px;
  margin-bottom: 22px; }
  @media (max-width: 767px) {
    .card-icon__note {
      margin-bottom: 16px; } }

.card-icon__title {
  font-size: 18px;
  letter-spacing: 2.5px;
  margin-bottom: 12px; }

.card-icon__subtitle {
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  margin-bottom: 16px; }

.card-icon__desc {
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 24px; }

.card-icon__divider {
  display: block;
  height: 2px;
  width: 100%;
  background-color: white;
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .card-icon__divider {
      height: 1px;
      margin-bottom: 16px; } }

.card-info {
  position: relative; }
  .card-info.-is-hidden {
    opacity: 0;
    pointer-events: none;
    height: 0;
    transition: all .5s; }
  .card-info.-is-active {
    opacity: 1;
    pointer-events: visible;
    height: auto;
    transition: all .5s; }
  @media (min-width: 768px) {
    .card-info:hover .card-info__img.-hover {
      opacity: 1;
      transition: opacity .4s; }
    .card-info:hover .card-info__subtitle {
      opacity: .8;
      transition: all .3s; }
    .card-info:hover .card-info__title {
      color: #5A5A5A;
      transition: all .3s; } }

.card-info__top {
  margin-bottom: 18px; }

.card-info__img {
  height: 270px;
  min-height: 270px;
  width: 100%;
  min-width: 100%;
  transition: opacity .4s; }
  .card-info__img.-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .1s; }
  @media (max-width: 1024px) {
    .card-info__img {
      min-height: 180px; } }
  @media (max-width: 767px) {
    .card-info__img {
      min-height: 160px; } }

.card-info__subtitle {
  font-family: "FrutigerLTPro-Light", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 4px;
  transition: all .3s; }

.card-info__title {
  font-size: 15px;
  letter-spacing: 0.7px;
  transition: all .3s; }

.hero-main {
  min-height: 685px;
  width: 100%; }
  @media (max-width: 1024px) {
    .hero-main {
      min-height: 400px; } }
  @media (max-width: 767px) {
    .hero-main {
      min-height: 170px; } }
  .hero-main.-l {
    min-height: 760px; }
    @media (max-width: 1024px) {
      .hero-main.-l {
        min-height: 560px; } }
    @media (max-width: 850px) {
      .hero-main.-l {
        min-height: 400px; } }
    @media (max-width: 767px) {
      .hero-main.-l {
        min-height: 170px; } }

/* endinject */
/* inject:scss */
footer {
  padding: 45px 0; }
  @media (max-width: 767px) {
    footer {
      padding: 32px 0; } }

.footer-flex-container {
  align-items: flex-end; }
  @media (max-width: 767px) {
    .footer-flex-container {
      flex-direction: column;
      align-items: flex-start; } }

.footer-links {
  align-items: flex-end; }
  @media (max-width: 767px) {
    .footer-links {
      display: block;
      width: 100%;
      margin-bottom: 24px; } }

.footer-links__item {
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 26px;
  letter-spacing: 2.6px;
  margin-right: 32px;
  padding-right: 1px;
  transition: width .3s ease-in-out; }
  @media (max-width: 1024px) {
    .footer-links__item {
      font-size: 20px; } }
  @media (max-width: 767px) {
    .footer-links__item {
      font-size: 13px;
      letter-spacing: 1.7px;
      margin-right: 8px; }
      .footer-links__item:before {
        content: '/';
        position: absolute;
        right: -9px;
        bottom: 0; }
      .footer-links__item:last-child:before {
        display: none; } }
  @media (max-width: 550px) {
    .footer-links__item {
      font-size: 12px; } }
  .footer-links__item:after {
    content: '';
    position: absolute;
    left: -2px;
    bottom: -8px;
    width: 0;
    height: 2px;
    background-color: black;
    transition: width .3s ease-in-out; }
    @media (max-width: 767px) {
      .footer-links__item:after {
        display: none; } }
  .footer-links__item:hover {
    color: black;
    transition: width .3s ease-in-out; }
    .footer-links__item:hover:after {
      width: 100%;
      transition: width .3s ease-in-out; }

@media (max-width: 1024px) {
  .footer-logo {
    width: 20%; } }

@media (max-width: 767px) {
  .footer-logo {
    width: auto;
    height: 40px; } }

.footer-logo .footer-logo__img {
  max-width: 100%;
  max-height: 100%; }

header {
  position: fixed;
  width: 100vw;
  background-color: white;
  z-index: 80; }
  header.-bg-dark .header-links__item {
    color: white; }
    header.-bg-dark .header-links__item:hover {
      color: #DADADA; }
    header.-bg-dark .header-links__item.-is-active {
      color: #DADADA; }
  header.-bg-dark .header-logo {
    color: white; }
  header.-bg-dark .header-ham:before, header.-bg-dark .header-ham:after {
    background-color: #DADADA; }
  header.-bg-dark .header-ham__close {
    background-color: white; }

.header-wrapper {
  min-height: 140px; }
  @media (max-width: 850px) {
    .header-wrapper {
      min-height: 80px; }
      .header-wrapper .flex-between {
        align-items: center; } }

.header-logo {
  font-size: 66px; }
  @media (max-width: 850px) {
    .header-logo {
      font-size: 48px; } }
  @media (max-width: 550px) {
    .header-logo {
      font-size: 40px; } }

.header-links {
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif; }
  .header-links .header-links__item {
    font-size: 32px;
    letter-spacing: 1.3px;
    margin-right: 90px; }
    @media (max-width: 1024px) {
      .header-links .header-links__item {
        margin-right: 56px; } }
    .header-links .header-links__item:last-child {
      margin-right: 0; }
    .header-links .header-links__item:hover {
      color: #5A5A5A; }
    .header-links .header-links__item.-is-active {
      color: #5A5A5A; }
  @media (max-width: 850px) {
    .header-links {
      display: none; } }

.header-ham {
  height: 16px;
  display: none;
  position: relative;
  width: 38px; }
  @media (max-width: 850px) {
    .header-ham {
      display: block; } }
  .header-ham:before, .header-ham:after {
    content: '';
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: black;
    transition: transform .3s; }
  .header-ham:before {
    top: 0; }
  .header-ham:after {
    bottom: 0; }
  .header-ham.-is-active:before {
    transform: translateY(6px);
    transition: transform .3s; }
  .header-ham.-is-active:after {
    transform: translateY(-6px);
    transition: transform .3s; }

.header-ham__close {
  width: 40px;
  height: 2px;
  background-color: black;
  display: none; }
  @media (max-width: 550px) {
    .header-ham__close {
      width: 32px; } }
  .header-ham__close.-is-active {
    display: block; }

.menu {
  position: absolute;
  right: 0;
  overflow-x: hidden;
  z-index: 60;
  width: 100vw;
  height: 100vh;
  background-color: white;
  transform: translateX(100vw);
  transition: .3s ease-out;
  display: none; }
  .menu.-is-active {
    transform: translateX(0);
    transition: .3s ease-out; }
  .menu.-bg-dark {
    background-color: #313131; }
  .menu.-bg-gray {
    background-color: #E8E8E8; }
  @media (max-width: 850px) {
    .menu {
      display: block; } }

.menu__container {
  flex-direction: column;
  height: 100%;
  width: 100vw;
  padding: 80px 0 24px 0; }

.menu__links {
  padding: 64px 48px 0 48px; }
  @media (min-width: 768px) {
    .menu__links {
      padding-top: 104px;
      text-align: center; } }

.menu__links-item {
  display: block;
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 28px;
  letter-spacing: 1px;
  margin-bottom: 48px; }
  .-bg-dark .menu__links-item,
  .-bg-gray .menu__links-item {
    color: white; }
  @media (min-width: 768px) {
    .menu__links-item {
      font-size: 40px; } }

.menu__footer-item {
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 13px;
  letter-spacing: 1px; }
  .-bg-dark .menu__footer-item,
  .-bg-gray .menu__footer-item {
    color: white; }
  @media (min-width: 768px) {
    .menu__footer-item {
      font-size: 20px; } }

/* endinject */
/* inject:scss */
.about-hero-wrapper {
  margin-bottom: 100px; }
  @media (max-width: 1024px) {
    .about-hero-wrapper {
      margin-bottom: 56px; } }
  @media (max-width: 767px) {
    .about-hero-wrapper {
      margin-bottom: 48px; } }

.about-section {
  margin-bottom: 112px; }
  @media (max-width: 767px) {
    .about-section {
      margin-bottom: 64px; } }
  .about-section .about-section__indent {
    margin-left: 20%; }
    @media (max-width: 1024px) {
      .about-section .about-section__indent {
        margin-left: 10%; } }
    @media (max-width: 767px) {
      .about-section .about-section__indent {
        margin-left: 0; } }
  .about-section .about-section__title {
    font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-size: 40px;
    letter-spacing: 1.2px;
    line-height: 50px;
    margin-bottom: 96px;
    width: 65%; }
    @media (max-width: 1024px) {
      .about-section .about-section__title {
        width: 80%;
        margin-bottom: 64px; } }
    @media (max-width: 767px) {
      .about-section .about-section__title {
        width: 100%;
        font-size: 20px;
        letter-spacing: 0.5px;
        line-height: 30px;
        margin-bottom: 40px; } }
  .about-section .about-section__paragraph {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.6px;
    line-height: 35px;
    margin-bottom: 32px; }
    @media (max-width: 767px) {
      .about-section .about-section__paragraph {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 28px;
        margin-bottom: 16px; } }

@media (max-width: 850px) {
  .contact {
    margin-top: -16px;
    padding-top: 24px; } }

@media (max-width: 1024px) {
  .contact .wrapper-s {
    padding: 0 56px; } }

@media (max-width: 850px) {
  .contact .wrapper-s {
    padding: 0; } }

.contact-hero-wrapper {
  margin-bottom: 100px; }
  @media (max-width: 1024px) {
    .contact-hero-wrapper {
      margin-bottom: 72px; } }
  @media (max-width: 850px) {
    .contact-hero-wrapper {
      margin-bottom: 56px; } }
  @media (max-width: 767px) {
    .contact-hero-wrapper {
      margin-bottom: 40px; } }

.contact-heading {
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 40px;
  letter-spacing: 1px;
  line-height: 50px;
  margin-bottom: 80px; }
  @media (max-width: 1024px) {
    .contact-heading {
      font-size: 32px;
      letter-spacing: 0.8px;
      line-height: 40px;
      margin-bottom: 64px; } }
  @media (max-width: 850px) {
    .contact-heading {
      font-size: 30px;
      letter-spacing: 0.5px;
      line-height: 45px; } }
  @media (max-width: 767px) {
    .contact-heading {
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 30px;
      margin-bottom: 32px; } }

.contact-info {
  padding-bottom: 72px;
  margin-bottom: 72px;
  border-bottom: 2px solid black; }
  @media (max-width: 767px) {
    .contact-info {
      padding-bottom: 40px;
      margin-bottom: 40px; } }
  .contact-info .contact__info-wrapper {
    margin-bottom: 64px; }
    @media (max-width: 1024px) {
      .contact-info .contact__info-wrapper {
        margin-bottom: 48px; } }
    @media (max-width: 767px) {
      .contact-info .contact__info-wrapper {
        margin-bottom: 32px; } }
  .contact-info .contact__info-text {
    font-family: "FrutigerLTPro-Light", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-size: 26px;
    letter-spacing: 2.5px;
    line-height: 42px; }
    .contact-info .contact__info-text.-zh {
      font-family: "PingFangTC-Light", "微軟正黑體", Helvetica, Arial, sans-serif;
      margin-left: -2px; }
    @media (max-width: 1024px) {
      .contact-info .contact__info-text {
        font-size: 20px;
        letter-spacing: 1.6px;
        line-height: 32px; } }
    @media (max-width: 767px) {
      .contact-info .contact__info-text {
        font-size: 16px;
        letter-spacing: 1.2px;
        line-height: 25px; } }
  .contact-info .contact__info-link {
    font-size: 16px;
    font-family: "FrutigerLTPro-Light", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
    letter-spacing: 1.2px;
    color: #9B9B9B;
    margin-left: 16px; }
    .contact-info .contact__info-link .icon {
      font-size: 14px; }
    .contact-info .contact__info-link:hover {
      color: #5A5A5A; }
    @media (max-width: 767px) {
      .contact-info .contact__info-link {
        display: block;
        padding-top: 16px;
        margin-left: 0; }
        .contact-info .contact__info-link .icon {
          font-size: 12px; } }

.contact-form {
  margin-bottom: 40px; }
  .contact-form .form-basic {
    width: 58%; }
    @media (max-width: 1024px) {
      .contact-form .form-basic {
        width: 75%; } }
    @media (max-width: 767px) {
      .contact-form .form-basic {
        width: 100%; }
        .contact-form .form-basic .form-basic__btn {
          width: 100%; } }

.contact-modal {
  margin: 0 auto;
  width: 28%;
  background-color: white;
  padding: 56px 0; }
  @media (max-width: 1024px) {
    .contact-modal {
      width: 45%; } }
  @media (max-width: 850px) {
    .contact-modal {
      width: 50%; } }
  @media (max-width: 767px) {
    .contact-modal {
      width: 80%; } }
  .contact-modal .contact-modal__icon {
    margin-bottom: 16px; }
  .contact-modal .contact-modal__title {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    margin-bottom: 12px; }
  .contact-modal .contact-modal__note {
    font-family: "PingFangTC-Light", "微軟正黑體", Helvetica, Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 2px; }
  .contact-modal .contact-modal__close {
    right: 20px;
    top: 18px; }

.home__hero-wrapper {
  padding-top: 8px;
  margin-bottom: 80px; }
  @media (max-width: 1024px) {
    .home__hero-wrapper {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .home__hero-wrapper {
      margin-bottom: 32px; } }

.home__heading {
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 34px;
  letter-spacing: 1.6px;
  line-height: 50px; }
  @media (max-width: 850px) {
    .home__heading {
      font-size: 17px;
      letter-spacing: 0.6px;
      line-height: 24px; } }
  @media (max-width: 550px) {
    .home__heading {
      font-size: 16px; } }

.project-hero-wrapper {
  margin-bottom: 72px; }
  @media (max-width: 850px) {
    .project-hero-wrapper {
      margin-bottom: 56px; } }
  @media (max-width: 767px) {
    .project-hero-wrapper {
      margin-bottom: 48px; } }

.project-content {
  margin-bottom: 88px; }
  @media (max-width: 850px) {
    .project-content {
      margin-bottom: 64px; } }

.project-content__top {
  margin-bottom: 88px; }
  @media (max-width: 850px) {
    .project-content__top {
      margin-bottom: 48px; } }
  @media (max-width: 767px) {
    .project-content__top {
      margin-bottom: 36px; } }

.project-content__btm .-is-hidden {
  opacity: 0;
  pointer-events: none;
  height: 0; }

.project-content__btm .-is-active {
  opacity: 1;
  pointer-events: visible;
  height: auto;
  transition: .3s; }

@media (max-width: 767px) {
  .project-content__btm {
    flex-direction: column; } }

.project-content__left {
  width: 55%; }
  @media (max-width: 850px) {
    .project-content__left {
      width: 60%; } }
  @media (max-width: 767px) {
    .project-content__left {
      width: 100%;
      margin-bottom: 64px; } }

.project-content__right {
  width: 30%; }
  @media (max-width: 850px) {
    .project-content__right {
      width: 36%; } }
  @media (max-width: 767px) {
    .project-content__right {
      width: 100%; } }

.project-content__title {
  font-size: 30px;
  letter-spacing: 3.26px; }
  @media (max-width: 850px) {
    .project-content__title {
      font-size: 28px;
      letter-spacing: 3px; } }
  @media (max-width: 767px) {
    .project-content__title {
      font-size: 26px;
      letter-spacing: 2.8px; } }

.project-content__paragraph {
  letter-spacing: 1.8px;
  line-height: 33px;
  margin-bottom: 28px;
  text-align: justify; }
  @media (max-width: 850px) {
    .project-content__paragraph {
      font-size: 15px;
      letter-spacing: 1.2px;
      line-height: 30px; } }
  @media (max-width: 767px) {
    .project-content__paragraph {
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 28px; } }

.project-content__more {
  font-family: "FrutigerLTPro-Light", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  letter-spacing: 1.5px;
  color: #5A5A5A;
  transition: .3s; }
  .project-content__more:hover {
    color: #9B9B9B;
    transition: .3s; }
  .project-content__more.-is-hidden {
    opacity: 0;
    pointer-events: none;
    height: 0;
    transition: .3s; }
  @media (max-width: 850px) {
    .project-content__more {
      font-size: 15px;
      letter-spacing: 1.2px; } }
  @media (max-width: 767px) {
    .project-content__more {
      font-size: 14px; } }

.project-content__infos {
  flex-wrap: wrap; }
  .project-content__infos .project-content__info {
    min-width: 50%;
    margin-bottom: 32px; }
    @media (max-width: 850px) {
      .project-content__infos .project-content__info {
        margin-bottom: 24px; } }
    @media (max-width: 767px) {
      .project-content__infos .project-content__info {
        margin-bottom: 16px; } }
    .project-content__infos .project-content__info .project-content__info-title {
      font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
      font-size: 13px;
      letter-spacing: 0.6px;
      margin-bottom: 10px; }
      @media (max-width: 850px) {
        .project-content__infos .project-content__info .project-content__info-title {
          font-size: 12px;
          letter-spacing: 0.8px;
          margin-bottom: 8px; } }
      @media (max-width: 767px) {
        .project-content__infos .project-content__info .project-content__info-title {
          font-size: 11px; } }
    .project-content__infos .project-content__info .project-content__info-main,
    .project-content__infos .project-content__info .project-content__info-list-item {
      font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
      font-size: 13px;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      color: #5A5A5A; }
      @media (max-width: 850px) {
        .project-content__infos .project-content__info .project-content__info-main,
        .project-content__infos .project-content__info .project-content__info-list-item {
          font-size: 12px;
          letter-spacing: 0.8px;
          margin-bottom: 8px; } }
      @media (max-width: 767px) {
        .project-content__infos .project-content__info .project-content__info-main,
        .project-content__infos .project-content__info .project-content__info-list-item {
          font-size: 11px; } }
    .project-content__infos .project-content__info .project-content__info-list-item {
      line-height: 24px; }
      @media (max-width: 850px) {
        .project-content__infos .project-content__info .project-content__info-list-item {
          line-height: 16px; } }

.project-bar {
  position: relative;
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 14px;
  letter-spacing: 2px;
  border-bottom: 2px solid black;
  padding-bottom: 20px;
  margin-bottom: 40px; }
  .project-bar .project-bar__center {
    margin: 0 auto; }
  .project-bar .project-bar__arrow {
    position: absolute;
    top: 0; }
    .project-bar .project-bar__arrow.-left {
      left: 0; }
    .project-bar .project-bar__arrow.-right {
      right: 0; }
  .project-bar .btn-icon-text .icon.icon-cross {
    font-size: 18px; }
  .project-bar .btn-icon-text:hover {
    color: #5A5A5A;
    transition: .3s; }
  @media (max-width: 767px) {
    .project-bar .btn-icon-text__text {
      display: none; }
    .project-bar .icon-arrow {
      font-size: 30px; }
    .project-bar .btn-icon-text .icon.icon-cross {
      font-size: 24px;
      margin-right: 0; } }

.project-related .project-related__title {
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 24px;
  letter-spacing: 0.8px;
  margin-bottom: 32px; }

.projects {
  padding-top: 64px; }
  @media (max-width: 850px) {
    .projects {
      padding-top: 40px; } }
  @media (max-width: 767px) {
    .projects {
      padding-top: 32px; } }

.projects__heading {
  font-family: "FrutigerLTPro-Roman", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 34px;
  letter-spacing: 1.6px;
  line-height: 50px; }

.projects__cards {
  width: 100%;
  margin-bottom: 24px;
  overflow: -webkit-paged-x; }
  @media (max-width: 850px) {
    .projects__cards {
      justify-content: space-between; } }
  @media (max-width: 767px) {
    .projects__cards {
      flex-wrap: nowrap;
      flex-direction: column; } }
  .projects__cards .card-info {
    width: calc( ( 100% - 16px * 2 ) / 3.001);
    margin-bottom: 40px;
    margin-right: 16px; }
    .projects__cards .card-info:nth-child(3n) {
      margin-right: 0; }
    @media (max-width: 850px) {
      .projects__cards .card-info {
        width: calc(50% - 16px); }
        .projects__cards .card-info:nth-child(3n) {
          margin-right: 16px; }
        .projects__cards .card-info:nth-child(2n) {
          margin-right: 0; } }
    @media (max-width: 767px) {
      .projects__cards .card-info {
        width: 100%;
        margin-bottom: 32px; } }
    .projects__cards .card-info.-is-hidden {
      margin-bottom: 0; }

.projects__show-btn {
  display: none; }
  @media (max-width: 767px) {
    .projects__show-btn {
      display: flex;
      margin-bottom: 36px; } }

.service {
  color: white; }
  @media (max-width: 850px) {
    .service {
      margin-top: -16px;
      padding-top: 0; } }

.service-header {
  padding-top: 120px;
  margin-bottom: 80px;
  text-align: center; }
  @media (max-width: 850px) {
    .service-header {
      padding-top: 96px;
      margin-bottom: 72px; } }
  @media (max-width: 767px) {
    .service-header {
      padding-top: 56px;
      margin-bottom: 56px; } }
  .service-header .service-header__title {
    font-size: 30px;
    letter-spacing: 3.6px;
    margin-bottom: 22px; }
    @media (max-width: 767px) {
      .service-header .service-header__title {
        font-size: 22px;
        letter-spacing: 2.6px;
        line-height: 30px; }
        .service-header .service-header__title .-m-break {
          display: block; } }
  .service-header .service-header__subtitle {
    font-size: 14px;
    letter-spacing: 1.6px; }
    @media (max-width: 767px) {
      .service-header .service-header__subtitle {
        font-size: 12px;
        letter-spacing: 1.5px;
        line-height: 20px; }
        .service-header .service-header__subtitle .-m-break {
          display: block; } }

.service-grids {
  margin-bottom: 96px; }
  @media (max-width: 767px) {
    .service-grids {
      margin-bottom: 64px; } }
  .service-grids .service-grids__container {
    flex-wrap: wrap;
    margin-bottom: 48px; }
    .service-grids .service-grids__container.-second {
      justify-content: initial; }
      .service-grids .service-grids__container.-second .service-grids__wrapper:nth-child(2) {
        margin-left: auto; }
      .service-grids .service-grids__container.-second .service-grids__wrapper:nth-child(3) {
        margin-right: 5%; }
      @media (max-width: 767px) {
        .service-grids .service-grids__container.-second .service-grids__wrapper.-single {
          margin-right: 0;
          margin-bottom: 0; } }
    .service-grids .service-grids__container.-three .card-icon {
      min-width: 26%;
      max-width: 26%; }
    .service-grids .service-grids__container .service-grids__wrapper {
      flex-direction: column;
      margin-bottom: 48px; }
      .service-grids .service-grids__container .service-grids__wrapper.-two {
        width: 45%; }
        .service-grids .service-grids__container .service-grids__wrapper.-two .card-icon {
          max-width: 43%;
          min-width: 43%; }
      .service-grids .service-grids__container .service-grids__wrapper.-three {
        width: 100%; }
      .service-grids .service-grids__container .service-grids__wrapper.-single {
        width: 20%;
        flex-direction: initial;
        justify-content: space-between; }
        .service-grids .service-grids__container .service-grids__wrapper.-single .card-icon {
          max-width: 100%;
          min-width: 100%; }
      .service-grids .service-grids__container .service-grids__wrapper .service-grids__cards {
        width: 100%;
        min-height: 280px;
        margin-bottom: 48px; }
    .service-grids .service-grids__container .service-grids__single-card {
      width: 100%; }
    @media (max-width: 767px) {
      .service-grids .service-grids__container {
        flex-direction: column; }
        .service-grids .service-grids__container.-two .card-icon, .service-grids .service-grids__container.-three .card-icon, .service-grids .service-grids__container.-four .card-icon {
          max-width: 100%;
          margin-bottom: 56px; }
          .service-grids .service-grids__container.-two .card-icon:last-child, .service-grids .service-grids__container.-three .card-icon:last-child, .service-grids .service-grids__container.-four .card-icon:last-child {
            margin-bottom: 0; }
        .service-grids .service-grids__container .service-grids__wrapper {
          margin-bottom: 56px; }
          .service-grids .service-grids__container .service-grids__wrapper.-two, .service-grids .service-grids__container .service-grids__wrapper.-single {
            width: 100%; }
            .service-grids .service-grids__container .service-grids__wrapper.-two .card-icon, .service-grids .service-grids__container .service-grids__wrapper.-single .card-icon {
              max-width: 100%;
              margin-bottom: 64px; }
              .service-grids .service-grids__container .service-grids__wrapper.-two .card-icon:last-child, .service-grids .service-grids__container .service-grids__wrapper.-single .card-icon:last-child {
                margin-bottom: 0; }
          .service-grids .service-grids__container .service-grids__wrapper.-singles {
            flex-direction: column; }
          .service-grids .service-grids__container .service-grids__wrapper .service-grids__cards {
            flex-direction: column;
            min-height: 0; }
          .service-grids .service-grids__container .service-grids__wrapper .service-grids__single-card {
            width: 100%;
            margin-bottom: 56px; }
          .service-grids .service-grids__container .service-grids__wrapper .card-icon {
            max-width: 100%;
            margin-bottom: 64px; }
            .service-grids .service-grids__container .service-grids__wrapper .card-icon:last-child {
              margin-bottom: 0; } }
  .service-grids .service-grids__wrapper {
    width: 100%; }

.service-grids__dividers .service-grids__divider {
  min-width: 47%; }

.service-grids__divider:after {
  bottom: calc(50% - 1px);
  right: 0;
  content: '';
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed white; }

.service-grids__divider .service-grids__divider-text {
  z-index: 2;
  background-color: #313131;
  padding: 8px 32px;
  border: 1px solid white; }
  .service-grids__divider .service-grids__divider-text:before, .service-grids__divider .service-grids__divider-text:after {
    bottom: 0;
    content: '';
    width: 100%;
    height: 100%;
    box-sizing: content-box; }
  .service-grids__divider .service-grids__divider-text:before {
    right: 1px;
    border-left: 12px solid #313131; }
  .service-grids__divider .service-grids__divider-text:after {
    left: 1px;
    border-right: 12px solid #313131; }

.service-parallax {
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 520px; }
  @media (max-width: 767px) {
    .service-parallax {
      min-height: 220px;
      background-attachment: scroll; } }

.service-contact {
  padding: 124px 0 72px 0; }
  .service-contact .service-contact__title {
    font-family: "FrutigerLTPro-Light", Helvetica, Arial, "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-size: 30px;
    letter-spacing: 3px;
    color: black; }
  @media (max-width: 767px) {
    .service-contact {
      padding: 64px 0 48px 0; }
      .service-contact .service-contact__title {
        font-size: 20px;
        letter-spacing: 2px; } }

.service-contact__mails {
  margin-bottom: 24px;
  width: 100%;
  height: 90px; }
  @media (max-width: 767px) {
    .service-contact__mails {
      margin-bottom: 0; } }

.service-contact__icon {
  left: calc(50% - 46px);
  top: 0;
  opacity: 0;
  pointer-events: none; }

/* endinject */
/* inject:scss */
body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate(0, 0); }

[data-aos='fade-up'] {
  transform: translate(0, 50px); }

[data-aos='fade-down'] {
  transform: translate(0, -50px); }

[data-aos='fade-right'] {
  transform: translate(-50px, 0); }

[data-aos='fade-left'] {
  transform: translate(50px, 0); }

[data-aos='fade-up-right'] {
  transform: translate(-50px, 50px); }

[data-aos='fade-up-left'] {
  transform: translate(50px, 50px); }

[data-aos='fade-down-right'] {
  transform: translate(-50px, -50px); }

[data-aos='fade-down-left'] {
  transform: translate(50px, -50px); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate(0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate(0, 50px) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate(0, -50px) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate(-50px, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate(50px, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate(0, 50px) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate(0, -50px) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate(-50px, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate(50px, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate(0, 0); }

[data-aos='slide-up'] {
  transform: translateY(100%); }

[data-aos='slide-down'] {
  transform: translateY(-100%); }

[data-aos='slide-right'] {
  transform: translateX(-100%); }

[data-aos='slide-left'] {
  transform: translateX(100%); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

.CMSDeskContent .aos-init {
  opacity: 1 !important;
  transform: translate(0, 0); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  height: 100%; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  height: 100%; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .slick-slide:focus {
    outline: none; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  margin-top: -50px;
  position: relative;
  z-index: 1;
  text-align: center; }
  .slick-dots > li {
    display: inline-block;
    margin: 0 5px; }
    .slick-dots > li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: #fff; }
    .slick-dots > li.slick-active button {
      background: #000;
      opacity: 1; }

/* endinject */
