.contact {
	@include media('below-small') {
    margin-top: -16px;
    padding-top: 24px;
	}

  .wrapper-s {
    @include media('below-desktop') {
      padding: 0 56px;
    }
    @include media('below-small') {
      padding: 0;
    }
  }
}

.contact-hero-wrapper {
  margin-bottom: 100px;

  @include media('below-desktop') {
    margin-bottom: 72px;
	}
  @include media('below-small') {
    margin-bottom: 56px;
	}
  @include media('below-tablet') {
    margin-bottom: 40px;
	}
}

.contact-heading {
  font-family: $second-font;
  font-size: 40px;
  letter-spacing: 1px;
  line-height: 50px;
  margin-bottom: 80px;

  @include media('below-desktop') {
    font-size: 32px;
    letter-spacing: 0.8px;
    line-height: 40px;
    margin-bottom: 64px;
	}
  @include media('below-small') {
    font-size: 30px;
    letter-spacing: 0.5px;
    line-height: 45px;
	}
  @include media('below-tablet') {
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 30px;
    margin-bottom: 32px;
	}
}

.contact-info {
  padding-bottom: 72px;
  margin-bottom: 72px;
  border-bottom: 2px solid $c-main-dark;

  @include media('below-tablet') {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  .contact__info-wrapper {
    @extend %last-no-margin-btm;
    margin-bottom: 64px;

    @include media('below-desktop') {
      margin-bottom: 48px;
    }
    @include media('below-tablet') {
      margin-bottom: 32px;
    }
  }
  .contact__info-text {
    @extend %block;
    font-family: $second-font-light;
    font-size: 26px;
    letter-spacing: 2.5px;
    line-height: 42px;

    &.-zh {
      font-family: $base-font-light;
      margin-left: -2px;
    }

    @include media('below-desktop') {
      font-size: 20px;
      letter-spacing: 1.6px;
      line-height: 32px;
    }
    @include media('below-tablet') {
      font-size: 16px;
      letter-spacing: 1.2px;
      line-height: 25px;
    }
  }
  .contact__info-link {
    font-size: 16px;
    font-family: $second-font-light;
    letter-spacing: 1.2px;
    color: $c-font-gray;
    margin-left: 16px;

    .icon {
      font-size: 14px;
    }
    &:hover {
      color: $c-font-hover;
    }

    @include media('below-tablet') {
      display: block;
      padding-top: 16px;
      margin-left: 0;

      .icon {
        font-size: 12px;
      }
    }
  }
}

.contact-form {
  margin-bottom: 40px;

  .form-basic {
    width: 58%;

    @include media('below-desktop') {
      width: 75%;
    }
    @include media('below-tablet') {
      width: 100%;

      .form-basic__btn {
        width: 100%;
      }
    }
  }
}

.contact-modal {
  @extend %text--center;
  @extend %position-relative;
  margin: 0 auto;
  width: 28%;
  background-color: $c-main-light;
  padding: 56px 0;

  @include media('below-desktop') {
    width: 45%;
  }
  @include media('below-small') {
    width: 50%;
  }
  @include media('below-tablet') {
    width: 80%;
  }

  .contact-modal__icon {
    margin-bottom: 16px;
  }
  .contact-modal__title {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 2px;
    margin-bottom: 12px;
  }
  .contact-modal__note {
    font-family: $base-font-light;
    font-size: 14px;
    letter-spacing: 2px;
  }
  .contact-modal__close {
    @extend %position-absolute;
    right: 20px;
    top: 18px;
  }
}