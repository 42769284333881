.hero-main {
  @extend %background-cover;
  min-height: 685px;
  width: 100%;

	@include media('below-desktop') {
    min-height: 400px;
	}
	@include media('below-tablet') {
    min-height: 170px;
	}

  &.-l {
    min-height: 760px;

    @include media('below-desktop') {
      min-height: 560px;
    }
    @include media('below-small') {
      min-height: 400px;
    }
    @include media('below-tablet') {
      min-height: 170px;
    }
  }
}