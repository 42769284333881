/* inject:scss */


@import "pages/_about.scss";


@import "pages/_contact.scss";


@import "pages/_home.scss";


@import "pages/_project.scss";


@import "pages/_projects.scss";


@import "pages/_service.scss";


/* endinject */