.card-icon {
  text-align: center;
}

.card-icon__img {
  margin-bottom: 16px;

  @include media('below-tablet') {
    margin-bottom: 8px;
  }
}

.card-icon__note {
  display: block;
  font-family: $second-font;
  font-size: 14px;
  letter-spacing: 0.6px;
  margin-bottom: 22px;

  @include media('below-tablet') {
    margin-bottom: 16px;
  }
}

.card-icon__title {
  font-size: 18px;
  letter-spacing: 2.5px;
  margin-bottom: 12px;
}

.card-icon__subtitle {
  font-family: $second-font;
  font-size: 18px;
  letter-spacing: 0.3px;
  margin-bottom: 16px;
}

.card-icon__desc {
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 24px;
}

.card-icon__divider {
  display: block;
  height: 2px;
  width: 100%;
  background-color: $c-main-light;
  margin-bottom: 20px;

  @include media('below-tablet') {
    height: 1px;
    margin-bottom: 16px;
  }
}
