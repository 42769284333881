footer {
  padding: 45px 0;

  @include media('below-tablet') {
    padding: 32px 0;
  }
}

.footer-flex-container {
  align-items: flex-end;
  @include media('below-tablet') {
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer-links {
  align-items: flex-end;
  @include media('below-tablet') {
    display: block;
    width: 100%;
    margin-bottom: 24px;
  }
}

.footer-links__item {
  @extend %position-relative;
  @extend %last-no-margin;
  font-family: $second-font;
  font-size: 26px;
  letter-spacing: 2.6px;
  margin-right: 32px;
  padding-right: 1px;
  transition: width .3s ease-in-out;

  @include media('below-desktop') {
    font-size: 20px;
  }
  @include media('below-tablet') {
    font-size: 13px;
    letter-spacing: 1.7px;
    margin-right: 8px;

    &:before {
      content: '/';
      position: absolute;
      right: -9px;
      bottom: 0;
    }
    &:last-child:before {
      display: none;
    }
  }
  @include media('below-mobile') {
    font-size: 12px;
  }

  &:after {
    content: '';
    position: absolute;
    left: -2px;
    bottom: -8px;
    width: 0;
    height: 2px;
    background-color: $default-color;
    transition: width .3s ease-in-out;

    @include media('below-tablet') {
      display: none;
    }
  }
  &:hover {
    color: black;
    transition: width .3s ease-in-out;
    &:after {
      width: 100%;
      transition: width .3s ease-in-out;
    }
  }
}

.footer-logo {
  @include media('below-desktop') {
    width: 20%;
  }
  @include media('below-tablet') {
    width: auto;
    height: 40px;
  }

  & .footer-logo__img {
    max-width: 100%;
    max-height: 100%;
  }
}