.bookshelf {
  @extend %flex-between;
  flex-wrap: wrap;
}

.bookshelf__container {
  @extend %flex-center-all;
  height: 140px;
  min-width: 20%;
  margin-bottom: 64px;

  @include media('below-tablet') {
    height: auto;
    max-width: 40%;
    margin-bottom: 40px;

    &:nth-last-child(-n+2) {
      margin-bottom: 0;
    }

    & img {
      max-width: 100%;
    }
  }
}