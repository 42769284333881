.project-hero-wrapper {
  margin-bottom: 72px;

  @include media('below-small') {
    margin-bottom: 56px;
	}
  @include media('below-tablet') {
    margin-bottom: 48px;
	}
}

.project-content {
  margin-bottom: 88px;

  @include media('below-small') {
    margin-bottom: 64px;
	}
}

.project-content__top {
  margin-bottom: 88px;

  @include media('below-small') {
    margin-bottom: 48px;
	}
  @include media('below-tablet') {
    margin-bottom: 36px;
	}
}

.project-content__btm {
  @extend %flex-between;

  & .-is-hidden {
    opacity: 0;
    pointer-events: none;
    height: 0;
    // transition: .1s;
  }
  & .-is-active {
    opacity: 1;
    pointer-events: visible;
    height: auto;
    transition: .3s;
  }

  @include media('below-tablet') {
    flex-direction: column;
  }
}

.project-content__left {
  width: 55%;
  @include media('below-small') {
    width: 60%;
  }
  @include media('below-tablet') {
    width: 100%;
    margin-bottom: 64px;
  }
}

.project-content__right {
  width: 30%;
  @include media('below-small') {
    width: 36%;
  }
  @include media('below-tablet') {
    width: 100%;
  }
}

.project-content__title {
  font-size: 30px;
  letter-spacing: 3.26px;

  @include media('below-small') {
    font-size: 28px;
    letter-spacing: 3px;
  }
  @include media('below-tablet') {
    font-size: 26px;
    letter-spacing: 2.8px;
  }
}

.project-content__paragraph {
  // @extend %last-no-margin-btm;
  letter-spacing: 1.8px;
  line-height: 33px;
  margin-bottom: 28px;
  text-align: justify;

  @include media('below-small') {
    font-size: 15px;
    letter-spacing: 1.2px;
    line-height: 30px;
  }
  @include media('below-tablet') {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 28px;
  }
}

.project-content__more {
  font-family: $second-font-light;
  letter-spacing: 1.5px;
  color: $c-font-hover;
  transition: .3s;

  &:hover {
    color: $c-font-gray;
    transition: .3s;
  }
  &.-is-hidden {
    opacity: 0;
    pointer-events: none;
    height: 0;
    transition: .3s;
  }

  @include media('below-small') {
    font-size: 15px;
    letter-spacing: 1.2px;
  }
  @include media('below-tablet') {
    font-size: 14px;
  }
}

.project-content__infos {
  @extend %flex-between;
  flex-wrap: wrap;

  & .project-content__info {
    min-width: 50%;
    margin-bottom: 32px;

    @include media('below-small') {
      margin-bottom: 24px;
    }
    @include media('below-tablet') {
      margin-bottom: 16px;
    }

    & .project-content__info-title {
      @extend %block;
      font-family: $second-font;
      font-size: 13px;
      letter-spacing: 0.6px;
      margin-bottom: 10px;

      @include media('below-small') {
        font-size: 12px;
        letter-spacing: 0.8px;
        margin-bottom: 8px;
      }
      @include media('below-tablet') {
        font-size: 11px;
      }
    }
    & .project-content__info-main,
    & .project-content__info-list-item  {
      font-family: $second-font;
      font-size: 13px;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      color: $c-font-hover;

      @include media('below-small') {
        font-size: 12px;
        letter-spacing: 0.8px;
        margin-bottom: 8px;
      }
      @include media('below-tablet') {
        font-size: 11px;
      }
    }
    & .project-content__info-list-item {
      @extend %last-no-margin-btm;
      line-height: 24px;

      @include media('below-small') {
        line-height: 16px;
      }
    }
  }
}

.project-bar {
  position: relative;
  font-family: $second-font;
  font-size: 14px;
  letter-spacing: 2px;
  border-bottom: 2px solid $c-main-dark;
  padding-bottom: 20px;
  margin-bottom: 40px;

  & .project-bar__center {
    margin: 0 auto;
  }
  & .project-bar__arrow {
    position: absolute;
    top: 0;
    &.-left { left: 0; }
    &.-right { right: 0; }
  }

  & .btn-icon-text .icon.icon-cross {
    font-size: 18px;
  }
  & .btn-icon-text {
    &:hover {
      color: $c-font-hover;
      transition: .3s;
    }
  }

  @include media('below-tablet') {
    .btn-icon-text__text {
      display: none;
    }
    .icon-arrow {
      font-size: 30px;
    }
    .btn-icon-text .icon.icon-cross {
      font-size: 24px;
      margin-right: 0;
    }
  }
}

.project-related {
  & .project-related__title {
    font-family: $second-font;
    font-size: 24px;
    letter-spacing: 0.8px;
    margin-bottom: 32px;
  }
}