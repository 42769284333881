.form-basic__wrapper {
  margin-bottom: 40px;
}

.form-basic__bar {
  @extend %position-relative;
  @extend %last-no-margin;
  margin-bottom: 26px;

  @include media('below-tablet') {
    margin-bottom: 24px;
  }

  & .icon {
    @extend %position-absolute;
    bottom: 17px;
    right: 16px;

    &.icon-chevron {
      transform: rotate(90deg);
    }
  }
  &.-is-error {
    .form-basic__bar-title {
      color: $c-error;
    }
  }
}

.form-basic__bar-title {
  @extend %flex-between;
  margin-bottom: 16px;

  @include media('below-tablet') {
    margin-bottom: 8px;
  }

  .form-basic__bar-label {
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 30px;
    margin-left: 2px;

    @include media('below-tablet') {
      font-size: 16px;
      letter-spacing: 1.6px;
    }
  }
  .form-basic__bar-msg {
    font-size: 13px;
    letter-spacing: 1.3px;
    line-height: 30px;

    @include media('below-tablet') {
      font-size: 12px;
    }
  }
}

.form-basic__bar-input,
.form-basic__bar-select {
  font-family: $base-font-light;
  font-size: 20px;
  letter-spacing: 2px;
  width: 100%;
  padding: 14px;
  background-color: $c-main-light;

  &::placeholder {
    color: $c-font-gray;
  }

  @include media('below-tablet') {
    font-size: 16px;
    letter-spacing: 1.6px;
  }
}

.form-basic__bar-select {
  border-radius: 0;
  border: 1px solid $c-main-dark;
  color: $c-font-gray;
}