.modal {
  @extend %flex-center-horizontal;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-modal;
  width: 100vw;
  height: 100vh;
  background-color: black;
  padding: 48px 0;

  transition: .3s;
  pointer-events: none;
  opacity: 0;

  &.-is-active {
    transition: .3s;
    pointer-events: visible;
    opacity: 1;
    background-color: rgba(black, .8);
  }
}