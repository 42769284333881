@keyframes transitionIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes transitionOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.transit-icon {
  & .transit-icon__target {
    opacity: 0;

    &:first-child {
      opacity: 1;
    }
  }
  &.-three:hover {
    & .transit-icon__target {
      animation-fill-mode: forwards;
      animation-duration: 0.8s;
      &:first-child {
        animation-name: transitionOut;
      }
      &:nth-child(2) {
        animation-name: transitionIn;
        animation-delay: 0.2s;
      }
      &:last-child {
        animation-name: transitionIn;
        animation-delay: 0.4s;
      }
    }
  }
}