.btn-icon-text {
  @extend %flex-center-all;
  transition: all .5s;

  &.-is-hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all .5s;
  }

  & .icon-chevron {
    transform: rotate(90deg);
  }
  & .icon-arrow {
    &.-right {
      transform: rotate(180deg);
    }
  }

  & .icon {
    font-size: 22px;

    @include media('below-tablet') {
      font-size: 12px;
      margin-top: -2px;
    }
  }

  &.-icon-left {
    .icon {
      margin-right: 18px;
    }
  }
  &.-icon-right {
    .icon {
      margin-left: 18px;
    }
  }
}

.btn-icon-text__text {
  @extend %block;
  font-family: $second-font-light;
  font-size: 30px;
  letter-spacing: 5.08px;
  margin-right: 10px;

  @include media('below-tablet') {
    font-family: $second-font;
    font-size: 12px;
    letter-spacing: 1.3px;
  }

  .-s & {
    font-family: $second-font;
    font-size: 14px;
    letter-spacing: 2px;
    margin-top: 1px;
  }
  .-icon-left &,
  .-icon-right & {
    margin-right: 0;
  }
}