.gallery__img {
  // @extend %background-cover;
  // width: 100%;

  @include media('below-tablet') {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.-l {
    // min-height: 760px;

    @include media('below-desktop') {
      // min-height: 560px;
    }
    @include media('below-small') {
      // min-height: 400px;
    }
    @include media('below-tablet') {
      // min-height: 170px;
    }
  }
  &.-m {
    // @extend %last-no-margin;
    // // min-height: 450px;
    // margin-right: 3%;

    @include media('below-desktop') {
      // min-height: 320px;
    }
    @include media('below-small') {
      // min-height: 240px;
    }
    @include media('below-tablet') {
      // min-height: 160px;
    }
  }
  &.-s {
    @extend %last-no-margin;
    // min-height: 600px;
    // max-width: calc(100% / 3 - 3%);
    // margin-right: 3%;

    @include media('below-desktop') {
      // min-height: 560px;
    }
    @include media('below-small') {
      // min-height: 420px;
    }
    @include media('below-tablet') {
      // min-height: 400px;
    }
  }
}

.gallery__container {
  @extend %flex-between;
  margin-bottom: 48px;
  @include media('below-tablet') {
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.gallery__wrapper {
  @extend %flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  @include media('below-tablet') {
    flex-direction: column;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.-s {
    @extend %last-no-margin;
    max-width: calc(100% / 3 - 3%);
    margin-right: 3%;
    @include media('below-tablet') {
      max-width: 100%;
      margin-right: 0;
    }
  }
  &.-m {
    @extend %last-no-margin;
    margin-right: 3%;

    @include media('below-tablet') {
      margin-right: 0;
    }
  }

  & .gallery__img {
    max-width: 100%;
    min-width: 100%;
  }
}