.subway-bars__bar {
  @extend %flex;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $c-main-dark;

  @include media('below-tablet') {
    justify-content: space-between;
  }
}

.subway-bars__bar-left {
  width: 20%;
  padding-left: 4px;
  @include media('below-tablet') {
    width: 25%;
  }
}
.subway-bars__bar-right {
  width: 80%;
  @include media('below-tablet') {
    width: 75%;
  }
}

.subway-bars__bar-note,
.subway-bars__bar-title {
  letter-spacing: 1.5px;

  @include media('below-tablet') {
    font-size: 12px;
    letter-spacing: 0.8px;
    line-height: 20px;
    word-wrap: break-word;
  }
}